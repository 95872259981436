import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../../layout/CardSection'
import { Link } from 'gatsby'
import { Button, Container, Text } from 'theme-ui'
import Breadcrumbs from '../../components/Breadcrumbs/ServiceCrumbs'

export default () => {
  const title = 'Private Label'
  const styles = {
    flex: {
      display: 'flex',
    },
    running: {
      fontSize: `1.25em`,
    },
  }
  return (
    <>
      <Seo
        title='How to Launch Your Private Label Skincare Line'
        description='Launching skincare products doesn’t have to be an uphill struggle. With the help of Pacific Beauty, you will get your medical-grade skincare line up and running before you can say "clear skin." With our Private Label program you choose from our vast collection of stock products to incorporate into your brand.'
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='Private Label - How it Works' />
          <CardSection omitTitle>
            <Text sx={styles.running}>
              These are the five steps we’ll walk you through:
              <h3>1. Choosing the Products</h3>
              <p>
                The most exciting, yet daunting, part of launching your skincare
                line is often selecting the products. Fortunately, our
                representatives have a wealth of knowledge in what works in the
                market, ensuring you make the right choice for your customer
                base. We will provide you with samples and consultation at no
                extra cost.
              </p>
              <h3>2. Selecting the Packaging</h3>
              <p>
                Once you know which product you want, it is time to turn your
                attention to how you will package it. We know that getting it
                right for your brand is vital, so we’ll take care to give you a
                variety of options that align with your company’s vision and
                message.
              </p>
              <h3>3. Working on Your Branding</h3>
              <p>
                Although you might already have some ideas about your brand
                identity, we’ll work with you to take things one step further
                and cement your strategy. The right marketing can make all the
                difference in your product line’s success! We’ll pay special
                attention to ensuring your brand screams quality and has a
                suitable focus.
              </p>
              <h3>4. Package Design</h3>
              <p>
                Even after you have chosen which packaging you want, the work
                isn’t over on this front. We’re not just talking about hastily
                sticking a logo onto a tube – we aim to create a striking design
                that encapsulates your brand. This process usually involves
                plenty of back and forth between you and our design team.
              </p>
              <h3>5. Selling and Marketing </h3>
              <p>
                Once we have created a product and packaging that you are happy
                with, it is time to move on to selling it. We won’t just leave
                you on your own at this point – our experienced professionals
                will guide you through the process to ensure you’re set up for
                success.
              </p>
              <h3>Final Words </h3>
              <p>
                If you think we’d make a dream team to launch your branded
                skincare line, <Link to='/contact/'>get in touch</Link> with
                Pacific Beauty now.
              </p>
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/contact/'>
              Contact Us
            </Button>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
